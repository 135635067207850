<template>
  <div class="content">
    <el-row class="bgW">
      <el-col :span="22">
        <!--检索条件-->
        <el-form class="search" label-suffix="：" :inline="true" size="mini" :model="search">
          <el-form-item label="门店/门诊">
            <el-select
              v-model="search.shop_id"
              clearable
              remote
              filterable
              :remote-method="remoteMethod"
              placeholder="请输入"
              class="w120"
            >
              <el-option
                v-for="item in options.shop_id"
                :key="item.shopId"
                :label="item.shopName"
                :value="item.shopId"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="姓名">
            <el-input
              v-model="search.customer_name"
              maxlength="30"
              class="w84"
              placeholder="请输入"
              clearable
            />
          </el-form-item>
          <el-form-item label="手机号">
            <el-input
              v-model="search.telephone"
              maxlength="30"
              class="w84"
              placeholder="请输入"
              clearable
            />
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="2" style="text-align: right;">
        <el-button type="primary" icon="el-icon-search" size="mini" @click="getList('search')">搜索</el-button>
      </el-col>
    </el-row>
    <div class="accountCon">
      <!--列表-->
      <el-row>
        <el-col :span="24">
          <el-table
            :data="list"
            size="small"
            border
            v-loading="loading"
            :cell-style="$style.cellStyle"
            :header-cell-style="$style.rowClass"
          >
           <ElTableColumn label="门店" width="200" prop="shop_name"  fixed="left"/>
            <ElTableColumn label="姓名" prop="customer_name" width="200"  fixed="left"/>
            <ElTableColumn label="联系电话" prop="telephone" width="200" fixed="left"/>
            <ElTableColumn label="商品名称" width="200" prop="goods_name" />
            <ElTableColumn label="兑换时间" width="200" prop="created_at" />
            <ElTableColumn label="是否核销" width="200" prop="status" />
            <ElTableColumn label="核销时间" prop="use_time" />
          </el-table>
          <pagination
            class="page tc mt10"
            :total="page.total"
            :page.sync="page.page"
            :limit.sync="page.limit"
            @pagination="getList"
          />
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { getPointGoodsEnableLog } from "@/api/client";
import pagination from "@/components/Pagination";
import { getShopList } from "@/api/shop";


class Search {
  shop_id = "";
  customer_name = "";
  telephone = "";
}
class Page {
  total = 0;
  page = 1;
  limit = 10;
}
class Options {
  shop_id = [];
}
export default {
  name: "PointList",
  components: {
    pagination
  },
  data() {
    return {
      search: new Search(),
      page: new Page(),
      options: new Options(),
      list:[],
      loading:false,
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList(option){
      this.loading = true;
      if (option === "search") this.page = new Page();
      getPointGoodsEnableLog({ ...this.search, ...this.page}).then(
        res => {
          this.list = res.data;
          this.page.total = res.total;
          this.loading = false;
        }
      );
    },
    // 搜索门店
    remoteMethod(e) {
      getShopList({ page: 1, limit: 100000, shopName: e }).then(res => {
        this.options.shop_id = res.list;
      });
    },
    // 跳转按钮
  }
};
</script>

<style lang="scss" scoped>

</style>
